import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

console.log(window.location.pathname !== '/')
if (window.location.pathname !== '/') {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  const staticRoot = document.getElementById('static-root');
  staticRoot.style.display = 'none';
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();

} else {
  const rootVariable = document.getElementById('root');
  rootVariable.style.display = 'none';
}


