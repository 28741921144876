/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Paper,
  Grid2,
  TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { css } from '@emotion/react';

import { ContractContext } from '../contexts/ContractContext';

const ContractReview = () => {
  const { contractData } = useContext(ContractContext);
  const parties = contractData.contractData.parties;
  const specifications = contractData.contractData.specifications;
  const remarks = contractData.contractData.remarks;

  console.log(contractData);
  return (
    <Grid2
      container
      direction="row"
      alignItems="flex-start"
      justifyContent="center"
      sx={{ height: '100%' }}
      spacing={2}>
      <Grid2 size={{ xs: 11, xm: 11, md: 10, lg: 8, xl: 8 }}>
        <Paper elevation={8} sx={componentStyles.contractDetailContainer}>
          <InputWrapper label={'Contract ID'} value={contractData.contractId} />
          <AccordionSet title="Seller Details">
            <InputWrapper label={'Name'} value={parties.sellerDetails.name} />
            <InputWrapper label={'GST No.'} value={parties.sellerDetails.gstNo} />
            <InputWrapper label={'Contact No.'} value={parties.sellerDetails.contactNo} />
            <InputWrapper label={'Email ID'} value={parties.sellerDetails.email} />
          </AccordionSet>
          <AccordionSet title="Buyer Details">
            <InputWrapper label={'Name'} value={parties.buyerDetails.name} />
            <InputWrapper label={'GST No.'} value={parties.buyerDetails.gstNo} />
            <InputWrapper label={'Contact No.'} value={parties.buyerDetails.contactNo} />
            <InputWrapper label={'Email ID'} value={parties.buyerDetails.email} />
          </AccordionSet>
          <AccordionSet title="Product Specifications">
            <InputWrapper label={'Item Name'} value={specifications.item} />
            <InputWrapper label={'HSN Code'} value={specifications.hsnCode} />
            <InputWrapper label={'Quantity'} value={`${specifications.quantity} ${specifications.quantityUnit}`} />
            <InputWrapper label={'Price per Kg.'} value={specifications.unitRate} />
            <InputWrapper label={'Is Item Hazardous'} value={specifications.isItemHazardous ? 'Yes' : 'No'} />
            <InputWrapper label={'Attached Images'} value={'None'} />
          </AccordionSet>
          <AccordionSet title="Delivery & Payment Terms">
            <InputWrapper label={'Delivery to be bourne by'} value={specifications.deliveryToBeBourne} />
            <InputWrapper label={'Payment Terms'} value={specifications.paymentTerms} />
            <InputWrapper label={'Weighment(s)'} value={specifications.weightments.join(', ')} />
            <InputWrapper label={'Delivery Date'} value={specifications.deliveryDate} />
          </AccordionSet>
          <AccordionSet title="Remarks">
            <InputWrapper label={'Remarks on Material'} value={remarks.remarksOnMaterial} />
            <InputWrapper label={'Any other remarks'} value={remarks.generalRemarks} />
            <InputWrapper label={'Additional Terms and Conditions'} value={remarks.additionalTnC} />
          </AccordionSet>
        </Paper>
      </Grid2>
      <div css={componentStyles.footerSection}>
        <FormGroup>
          <FormControlLabel control={<Checkbox defaultChecked />} label="The above summary is of the transaction described herewith and a mail would be sent to all concerned parties for initimation.
        If any party has an objection or needs any correction of terms and conditions in the said transaction they are requested to inform at kotharikewal7@gmail.com or the
        transaction shall be considered as accepted by all concerned parties. We request the concerning parties to kindly sing on the printout and attach it to the invoice/purchase order etc." />
        </FormGroup>
        <div>
          <Button variant={'contained'} color="success" sx={componentStyles.buttonStyles}>Verify</Button>
          <Button variant={'contained'} color="error" sx={componentStyles.buttonStyles}>Reject</Button>
        </div>
      </div>
    </Grid2>
  )
};

const InputWrapper = ({label, value}) => {
  return (
    <TextField
      disabled
      fullWidth
      value={value}
      label={label}
      size="small"
      margin="normal"
      variant={'standard'} />
  )
}

const AccordionSet = ({title, children}) => {
  return (
    <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <b>{title}</b>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
  );
}

const componentStyles = {
  contractDetailContainer: css({
    padding: 12,
    overflow: 'auto',
    marginTop: 24,
    maxHeight: 'calc(100% - 350px)'
  }),
  footerSection: css({
    margin: '32px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  }),
  buttonStyles: css({
    margin: '0 8px'
  })
}

export default ContractReview;