import React, { Component } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import PageContainer from './pages/PageContainer';
import SignIn from './pages/SignIn';
import ContractReview from './pages/ContractReview';

import Header from './components/Header';

import { ContractContextProvider } from './contexts/ContractContext';

import './App.css';
import logo from './logo.svg';

function App() {
  const router = createBrowserRouter([
    {
      path: "/contract/:contractId",
      element: <SignIn />,
    },
    {
      path: "/contract/:contractId/verification",
      element: <ContractReview />,
    },
    {
      path: "/",
      element: <span>Test</span>,
    },
  ]);

  return (
    <>
      <Header />
      <PageContainer>
        <React.StrictMode>
          <ContractContextProvider>
            <RouterProvider router={router} />
          </ContractContextProvider>
        </React.StrictMode>
      </PageContainer>
    </>
  );
}

export default App;
