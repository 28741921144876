/** @jsxImportSource @emotion/react */
import React, { useState, useContext } from 'react';
import { Button, Paper, Grid2, TextField, Alert, CircularProgress } from '@mui/material';
import { css } from '@emotion/react';
import { useNavigate, useParams } from 'react-router-dom';

import { getContract } from '../services/ContractService';

import { ContractContext } from '../contexts/ContractContext';


const SignIn = ({ props }) => {

  const { contractId } = useParams();
  const navigate = useNavigate();

  const [passKey, setPassKey] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [displayLoader, setDisplayLoader] = useState(false);

  const { setContractData } = useContext(ContractContext)

  const onPassKeyChange = (event) => {
    setPassKey(event.target.value);
    if (alertMessage.length > 0) {
      setAlertMessage('');
    }
  }

  const onSignInContinue = async () => {
    // call api
    // set context
    setDisplayLoader(true);
    const contractData = await getContract(contractId);
    console.log(contractData);
    setContractData({ ...contractData })
    setDisplayLoader(false);
    navigate(`/contract/${contractId}/verification`)
    // setAlertMessage('Invalid Client Password Key');
  }

  return (
    <div css={componentStyles.container}>
      <Grid2
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%' }}
        spacing={2}>
        <Grid2 size={{ xs: 11, xm: 11, md: 10, lg: 6, xl: 4 }}>
          <Paper elevation={9} sx={componentStyles.paperContainer}>
          { displayLoader ? (<CircularProgress />) : null}
            <h1 css={componentStyles.title}>Verify Contract</h1>
            <div css={componentStyles.inputContainer}>
              <div>
                <TextField
                  disabled
                  fullWidth
                  value={contractId}
                  label={'Contract ID'}
                  variant={'filled'}
                  sx={componentStyles.inputField} />
              </div>
              <div>
                <TextField
                  fullWidth
                  required
                  label={'Client Password Key'}
                  onChange={onPassKeyChange}
                  value={passKey}
                  sx={componentStyles.inputField} />
              </div>
              <Button
                onClick={onSignInContinue}
                disabled={passKey.length > 0 ? false : true}
                variant={'contained'}
                sx={componentStyles.continueButton}>Continue</Button>
              {alertMessage.length > 0 ? (
                <Alert variant="filled" severity="error">
                  {alertMessage}
                </Alert>
              ) : null}
            </div>
          </Paper>
        </Grid2>
      </Grid2>
    </div >
  );
}

const componentStyles = {
  container: css({
    height: '100%'
  }),
  paperContainer: css({
    height: '100%',
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }),
  inputContainer: css({
    display: 'flex',
    flexDirection: 'column',
    margin: '24px auto'
  }),
  title: css({
    margin: '16px auto'
  }),
  inputField: css({
    marginTop: '8px',
    marginBottom: '8px'
  }),
  continueButton: css({
    margin: '24px 0'
  })
}

export default SignIn;
