import { createContext, useState } from 'react';

export const ContractContext = createContext({
  contractData: {},
  setContractData: () => { }
});

export const ContractContextProvider = ({ children }) => {
  const [contractData, updateContractData] = useState({});

  const value = {
    contractData,
    setContractData: (contractInformation) => {
      updateContractData(contractInformation);
    }
  };

  return (
    <ContractContext.Provider value={value}>
      {children}
    </ContractContext.Provider>
  );
}