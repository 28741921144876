/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { css } from '@emotion/react';

const PageContainer = ({ children }) => {
  return (
    <div css={copmonentStyles.pageContainer}>{children}</div>
  )
};

const copmonentStyles = {
  pageContainer: css({
    height: 'calc(100% - 70px)',
    marginTop: '70px',
  })
};

export default PageContainer;