/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { css } from '@emotion/react';


const Header = ({ props }) => {
  return (
    <>
      <div css={componentStyle.header}>
        <div css={componentStyle.logoContainer}>
          <img css={componentStyle.image} src={'/veriTransactLogo.png'} />
        </div>
      </div>
    </>
  );
};

const componentStyle = {
  header: css({
    padding: '10px',
    height: '50px',
    backgroundColor: 'white',
    width: '100%',
    position: 'fixed',
    top: 0,
    zIndex: 10
  }),
  logoContainer: css({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    width: 250,
    height: 50,
    overflow: 'hidden',
  }),
  image: css({
    width: 250,
  })
}

export default Header;